import './companies.scss';
import { getTaggedElements } from '../../utils/get-tagged-element';

export function initOccasionButtons() {
  const occasionButtons = getTaggedElements('companies-choose-location-button');

  Array.from(occasionButtons).forEach(function (button) {
    button.addEventListener('click', handleOccasionButtonClick);
  });

  function handleOccasionButtonClick() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
