import SlimSelect from 'slim-select';
import * as qs from 'qs';

import { getTaggedElement } from '../../utils/get-tagged-element';
import { createBlogArticleElement } from './blog-article';
import { Article } from '../../utils/data-structures';

class Articles {
  $articlesList: HTMLElement;
  $articleTemplate: HTMLTemplateElement;
  slimSelect: SlimSelect;
  allArticles: Article[];

  constructor() {
    const $articlesSelect =
      getTaggedElement<HTMLSelectElement>('articles-select');
    const translatedPlaceholder: string =
      $articlesSelect.dataset.translatedPlaceholder || '';

    this.slimSelect = new SlimSelect({
      select: $articlesSelect,
      showSearch: true,
      placeholder: translatedPlaceholder,
      onChange: () => this.onTagsChange(),
    });

    this.$articlesList = getTaggedElement('articles-list');
    this.$articleTemplate =
      getTaggedElement<HTMLTemplateElement>('article-template');
    this.allArticles = window.preloadedData.articles || [];
    window.addEventListener('hashchange', () => this.reloadTagsFromHash());
  }

  public init() {
    this.reloadTagsFromHash();
  }

  private reloadArticlesList(articles: Article[]) {
    this.$articlesList.textContent = '';
    for (const article of articles) {
      const $article = createBlogArticleElement(this.$articleTemplate, article);
      this.$articlesList.appendChild($article);
    }
  }

  private onTagsChange() {
    this.applyTags(this.slimSelect.selected() as string[]);
  }

  private reloadTagsFromHash() {
    this.slimSelect.set(this.getQueryStringTags());

    const articles = this.visibleArticles();
    this.reloadArticlesList(articles);
  }

  private visibleArticles() {
    const tags = this.slimSelect.selected() as string[];

    return tags.length === 0
      ? this.allArticles
      : this.allArticles.filter((article) =>
          tags.some((tag) =>
            article.suitableOccasions.find((occassion) => occassion.id === tag),
          ),
        );
  }

  private applyTags(tagIds: string[]) {
    const qsTags = this.getQueryStringTags();
    const qsTagsStr = qsTags.join(',');
    const selectedTagsStr = tagIds.join(',');
    if (qsTagsStr !== selectedTagsStr) {
      window.location.hash = `tags=${selectedTagsStr}`;
    }
  }

  private getQueryStringTags() {
    const { tags } = qs.parse(window.location.hash.slice(1));
    return tags ? tags.split(',') : [];
  }
}

export function initArticles() {
  const articles = new Articles();
  articles.init();
  return articles;
}
