import { GamePhoto } from '../../../utils/data-structures';
import { isMobile, mapUrlBySize } from '../../../utils/responsive';
import { getTaggedElement } from '../../../utils/get-tagged-element';
import { initVideoPlayer } from '../../../components/modal/modal';

export function loadPredefinedPhotos() {
  const preloadedData = window.preloadedData.gamePhotos;
  if (preloadedData) {
    setHeaderImage(preloadedData);
  }
}

function setHeaderImage(photos: GamePhoto[]) {
  const $gameHeader = getTaggedElement<HTMLElement>('game-header');

  const mappedUrls = mapUrlBySize(photos);

  if (isMobile() && mappedUrls.mobile) {
    $gameHeader.style.backgroundImage = `url('${mappedUrls.mobile}')`;
  } else if (mappedUrls.desktop) {
    $gameHeader.style.backgroundImage = `url('${mappedUrls.desktop}')`;
  }
}

export function initGameLocationPage() {
  loadPredefinedPhotos();
  initVideoPlayer();
}
