import './progress-bar.scss';

// expected following html structure
//
// <div class="progress-bar">
//  <div class="progress-bar--indicator">
// </div>
export class ProgressBar {
  $progress: HTMLDivElement;

  total = 100;
  value = 0;

  constructor(private $container: Element) {
    const $indicator = $container.querySelector<HTMLDivElement>(
      '.progress-bar__indicator',
    );
    if (!$indicator) {
      throw new Error('Progress bar need progress indicator control');
    }
    this.$progress = $indicator;
  }

  setValue(value: number) {
    this.value = value;
    this.redraw();
  }

  setTotal(total: number) {
    this.total = total;
    this.redraw();
  }

  private redraw() {
    const progressPerc = Math.round((100 * this.value) / this.total);
    this.$progress.style.width = `${progressPerc}%`;
  }
}
