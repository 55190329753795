/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import './styles/base.scss';

import { runOnRoutes } from './javascript/routes/routes';
import { initArticles } from './javascript/components/articles/articles';
import { initHomePage } from './javascript/landings/home/init-home-page';
import { initLocationsPage } from './javascript/landings/locations/global/init-locations-page';
import { initPages } from './javascript/landings/init-pages';
import { initCompaniesGlobalPage } from './javascript/landings/companies/global/init-companies-global-page';
import { initContactGlobalPage } from './javascript/landings/contact/global/init-contact-global-page';
import { initContactLocationPage } from './javascript/landings/contact/location/init-contact-location-page';
import { initFranchiseGlobalPage } from './javascript/landings/franchise/global/init-franchise-global-page';
import { initFranchiseLocationPage } from './javascript/landings/franchise/landing/init-franchise-location-page';
import { initGameLocationPage } from './javascript/landings/game/location/init-game-location-page';
import { initGameBookingPage } from './javascript/landings/game/location/booking/init-game-booking-page';
import { initPortableActivitiesGlobalPage } from './javascript/landings/portable-activities/global/init-portable-activities-global-page';
import { initPortableActivitiesLocationPage } from './javascript/landings/portable-activities/location/init-portable-activities-location-page';

importAssets();

function init() {
  runOnRoutes('/*{/,}', () => {
    initHomePage();
  });

  runOnRoutes(['/*/companies'], () => {
    initCompaniesGlobalPage();
  });

  runOnRoutes('/*/locations/*', () => {
    initLocationsPage();
  });

  runOnRoutes(['/*/contact'], () => {
    initContactGlobalPage();
  });

  runOnRoutes(['/*/franchise'], () => {
    initFranchiseGlobalPage();
  });

  runOnRoutes(['/*/locations/*/franchise'], () => {
    initFranchiseLocationPage();
  });

  runOnRoutes(['/*/portable_activities'], () => {
    initPortableActivitiesGlobalPage();
  });

  runOnRoutes(['/*/locations/*/portable_activities'], () => {
    initPortableActivitiesLocationPage();
  });

  runOnRoutes(['/*/locations/*/contact'], () => {
    initContactLocationPage();
  });

  runOnRoutes(['/*/locations/*/games/*'], () => {
    initGameLocationPage();
  });

  runOnRoutes(['/*/locations/*/games/*/booking'], () => {
    initGameBookingPage();
  });

  runOnRoutes(['/*/blog', '/*/locations/*/blog'], () => {
    initArticles();
  });

  runOnRoutes(['/*/media', '/*/locations/*/media'], () => {
    initArticles();
  });

  initPages();
}

function importAssets() {
  require.context('../assets', true);
}

window.addEventListener('DOMContentLoaded', init);
