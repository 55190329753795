import './video-player.scss';

import enableSwipeListener, { SwipeEvent } from 'swipe-listener';

import { Modal } from '../modal/base-modal';
import { ProgressBar } from '../progress-bar/progress-bar';
import { Spinner } from '../spinner/spinner';
import { GameInfo, ContestantType } from '../../utils/data-structures';
import { getViewportSize, isMobile } from '../../utils/responsive';

interface VideoFilters {
  contestantType?: ContestantType;
}

export class VideoPlayer implements Modal {
  $video: HTMLVideoElement;
  $overlay: Element;
  $button: HTMLAnchorElement | null;
  $progressBars: HTMLElement[] = [];

  progressBar?: ProgressBar;

  games: GameInfo[] = [];

  currentVideoIndex = 0;
  spinner: Spinner;
  filters: VideoFilters = {};

  constructor(private $container: Element) {
    const $video = $container.querySelector('video');
    if (!$video) {
      throw new Error('Expected video element in the modal video player');
    }

    const $overlay = this.$container.querySelector(
      '.video-player-modal__controls',
    );

    if (!$overlay) {
      throw new Error('Expected overlay element in the modal video player');
    }
    this.$overlay = $overlay;
    this.spinner = new Spinner($overlay);

    const $progress = $container.querySelector('.progress-bar');
    if ($progress) {
      this.progressBar = new ProgressBar($progress);
    }
    this.$button = $container.querySelector('.video-player-modal__button');

    this.$video = $video;

    this.attachEvents();

    this.loadPredefinedVideos();
  }

  private loadPredefinedVideos() {
    if (window.preloadedData.videoPlayerGames) {
      this.setGames(window.preloadedData.videoPlayerGames);
    }
  }

  public next() {
    this.currentVideoIndex =
      (this.currentVideoIndex + 1) % this.visibleGames().length;
    this.applyCurrentGame();
    this.updateGameTitlesInProgressBars();
  }

  public previous() {
    const games = this.visibleGames();
    this.currentVideoIndex =
      (games.length + this.currentVideoIndex - 1) % games.length;
    this.applyCurrentGame();
    this.updateGameTitlesInProgressBars();
  }

  public playGameVideoById(id: string) {
    this.setCurrentVideoIndexByGameId(id);
    this.applyCurrentGame();
    this.updateGameTitlesInProgressBars();
  }

  public setGames(games: GameInfo[]) {
    this.games = games;

    this.setupUserInterfaceVisibility();
  }

  public applyFilters(filters: Partial<VideoFilters>) {
    this.filters = { ...this.filters, ...filters };

    this.setupUserInterfaceVisibility();
  }

  public onShow() {
    if (this.visibleGames().length > 0) {
      this.applyCurrentGame();
    }
  }

  public onHide() {
    this.$video.pause();
    this.$video.currentTime = 0;
  }

  private setCurrentVideoIndexByGameId(id: string) {
    const indexOfGame = this.visibleGames().findIndex((game) => game.id === id);
    this.currentVideoIndex = indexOfGame || 0;
  }

  private visibleGames() {
    return this.games.filter((game) => {
      return (
        !this.filters.contestantType ||
        game.contestantType === this.filters.contestantType
      );
    });
  }

  private setupUserInterfaceVisibility() {
    const games = this.visibleGames();

    const arrows = this.$container.querySelectorAll<HTMLElement>(
      '.video-player-modal__arrow',
    );
    arrows.forEach((arrow) => {
      arrow.style.visibility = games.length > 1 ? 'visible' : 'none';
    });

    this.$progressBars = Array.from(
      this.$container.querySelectorAll<HTMLElement>(
        '.video-player-modal__progress',
      ),
    );

    this.$progressBars.forEach((bar, index) => {
      const gameVisible = index < games.length;
      bar.style.display = gameVisible ? 'block' : 'none';
    });
    this.updateGameTitlesInProgressBars();
  }

  private onVideoProgress() {
    if (this.progressBar) {
      this.progressBar.setValue(this.$video.currentTime);
    }
  }

  private onVideoLoaded() {
    const { vw, vh } = getViewportSize();
    this.$video.width = Math.min(this.$video.videoWidth, vw);
    this.$video.height = Math.min(this.$video.videoHeight, vh);

    if (this.progressBar) {
      this.progressBar.setTotal(this.$video.duration);
    }

    this.spinner.hide();
  }

  private onSwipeGesture(ev: SwipeEvent) {
    if (ev.detail.directions.right) {
      return this.previous();
    }
    if (ev.detail.directions.left) {
      return this.next();
    }
  }

  private applyCurrentGame() {
    const games = this.visibleGames();

    if (games.length === 0) {
      this.$video.src = '';
      return;
    }
    const game = games[this.currentVideoIndex];

    if (this.$button) {
      this.$button.href = game.gameUrl;
    }

    this.$video.src =
      (isMobile() ? game.mobileVideoUrl : game.desktopVideoUrl) || '';
    this.$video.load();
    this.$video.play();
  }

  private attachEvents() {
    this.$video.addEventListener('ended', () => this.next());
    this.$video.addEventListener('loadeddata', () => this.onVideoLoaded());
    this.$video.addEventListener('loadstart', () => this.spinner.show());

    if (this.progressBar) {
      this.$video.addEventListener('timeupdate', () => this.onVideoProgress());
    }

    enableSwipeListener(this.$overlay, {
      mouse: false,
      touch: true,
    });
    this.$overlay.addEventListener('swipe', (ev) =>
      this.onSwipeGesture(ev as SwipeEvent),
    );
    this.$overlay.addEventListener('click', () =>
      this.$video.paused ? this.$video.play() : this.$video.pause(),
    );

    const $prev = this.$container.querySelector(
      '.video-player-modal__arrow-prev',
    );
    $prev &&
      $prev.addEventListener('click', (ev) => {
        this.previous();
        ev.stopPropagation();
      });

    const $next = this.$container.querySelector(
      '.video-player-modal__arrow-next',
    );
    $next &&
      $next.addEventListener('click', (ev) => {
        this.next();
        ev.stopPropagation();
      });
  }

  private updateGameTitlesInProgressBars() {
    const numberOfVisibleGames = this.visibleGames().length;

    if (this.visibleGames().length === 0) {
      return;
    }

    this.$progressBars?.forEach((bar, index) => {
      const $GameTitleContainer = bar.querySelector<HTMLSpanElement>(
        '.video-progress-bar__game-title',
      );

      if ($GameTitleContainer) {
        const nextIndex =
          (this.currentVideoIndex + index) % numberOfVisibleGames;
        $GameTitleContainer.innerText = this.visibleGames()[nextIndex].name;
      }
    });
  }
}
