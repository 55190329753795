import Swipe from 'swipejs';

import { getTaggedElement } from '../../utils/get-tagged-element';
import './swipe-js.scss';

export function initSlider($sliderSection: HTMLElement) {
  const $sliderContainer = getTaggedElement('slider', $sliderSection);
  const $nextButton = getTaggedElement('slider-next-button', $sliderSection);
  const $prevButton = getTaggedElement('slider-prev-button', $sliderSection);

  const slider = new Swipe($sliderContainer, {
    startSlide: 0,
    speed: 400,
    auto: 4000,
    draggable: false,
    continuous: true,
    disableScroll: false,
    stopPropagation: false,
  });

  const { next, prev } = slider;

  $nextButton.addEventListener('click', next);
  $prevButton.addEventListener('click', prev);
}
