const initializeValidation = (formElement: HTMLFormElement | Document) => {
  initErrorElements(formElement);
  initCaptchaValidation(formElement);
};

function initCaptchaValidation(formElement: HTMLFormElement | Document) {
  // recaptcha scripts are loaded using `async`, so it's not guaranteed
  // that the captcha was rendered during initial script execution.
  // Event listeners help solve the problem and check if the token is still valid.

  const captchaValidation = (e: Event) => {
    const recaptcha = formElement.querySelector<HTMLTextAreaElement>(
      '.g-recaptcha-response',
    );
    const recaptchaErrorContainer = formElement.querySelector<HTMLElement>(
      '.recaptcha-error-container',
    );
    if (recaptcha && recaptchaErrorContainer) {
      if (!recaptcha.value) {
        e.preventDefault();
        recaptchaErrorContainer.style.display = 'block';
      } else {
        recaptchaErrorContainer.style.display = 'none';
      }
    }
  };

  formElement.addEventListener('invalid', captchaValidation, { capture: true });
  formElement.addEventListener('submit', captchaValidation);
}

function initErrorElements(formElement: HTMLFormElement | Document) {
  const maxLengthElements: HTMLElement[] = Array.from(
    formElement.querySelectorAll('[data-input-maxlength-error]'),
  );

  maxLengthElements.forEach((errorElement) => {
    const query = errorElement.getAttribute('data-input-maxlength-error');
    if (query) {
      const input = formElement.querySelector(query) as HTMLInputElement;
      if (input) {
        input.addEventListener('input', (e: Event) => {
          const currentLength = (e.target as HTMLInputElement).value.length;
          const maxLength = (e.target as HTMLInputElement).maxLength;
          const difference = maxLength - currentLength;
          if (difference <= 10) {
            errorElement.style.display = 'block';
            errorElement.textContent = `${difference} left`;
          } else {
            errorElement.style.display = 'none';
          }
        });
      }
    }
  });

  const requiredErrorElements = formElement.querySelectorAll(
    '[data-input-required-error]',
  );

  requiredErrorElements.forEach((errorElement) => {
    const query = errorElement.getAttribute('data-input-required-error');
    if (query) {
      const input = formElement.querySelector(query) as HTMLInputElement;

      installInputError(
        errorElement as HTMLElement,
        input as HTMLInputElement,
        'valueMissing',
      );
    }
  });

  const patternErrorElements = formElement.querySelectorAll(
    '[data-input-pattern-error]',
  );

  patternErrorElements.forEach((errorElement) => {
    const query = errorElement.getAttribute('data-input-pattern-error');

    if (query) {
      const input = formElement.querySelector(query) as HTMLInputElement;

      installInputError(
        errorElement as HTMLElement,
        input as HTMLInputElement,
        'patternMismatch',
      );
    }
  });

  const typeErrorElements = formElement.querySelectorAll(
    '[data-input-type-error]',
  );

  typeErrorElements.forEach((errorElement) => {
    const query = errorElement.getAttribute('data-input-type-error');

    if (query) {
      const input = formElement.querySelector(query) as HTMLInputElement;

      installInputError(
        errorElement as HTMLElement,
        input as HTMLInputElement,
        'typeMismatch',
      );
    }
  });
}

function installInputError(
  errorElement: HTMLElement,
  input: HTMLInputElement,
  validityState: keyof ValidityState,
) {
  input.addEventListener('blur', () => {
    checkValidation(errorElement, input, validityState);
  });

  input.addEventListener('invalid', (event) => {
    event.preventDefault();

    checkValidation(errorElement, input, validityState);
  });
}

function checkValidation(
  errorElement: HTMLElement,
  input: HTMLInputElement,
  validityState: keyof ValidityState,
) {
  errorElement.style.display = input.validity[validityState] ? 'block' : 'none';
}

export { initializeValidation, checkValidation };
