import { getTaggedElement } from '../../utils/get-tagged-element';
import './dropdown.scss';

interface DropdownOptions {
  dropdownElement: HTMLDivElement;
  onSelect: (option?: SelectedItem) => void;
  onBlur?: () => void;
}

export interface SelectedItem {
  text: string;
  value: string | null;
}

export class Dropdown {
  private toggleButton: HTMLButtonElement;
  private optionsList: HTMLUListElement;
  private optionsArray: HTMLLIElement[];
  private focusedItemIndex = 0;
  private selectedItem?: SelectedItem;

  public dropdownElement: HTMLDivElement;
  public onSelect: () => void;
  public onBlur: (() => void) | undefined;

  constructor(options: DropdownOptions) {
    const { dropdownElement, onSelect, onBlur } = options;
    this.toggleButton = getTaggedElement('dropdown-toggle', dropdownElement);
    this.optionsList = getTaggedElement('options-list', dropdownElement);
    this.optionsArray = Array.from(this.optionsList.querySelectorAll('li'));
    this.dropdownElement = dropdownElement;
    this.onSelect = () => onSelect(this.selectedItem);
    this.onBlur = onBlur;
    this.addEventListeners();
  }

  public updateSelectedOptionByValue(value: string) {
    const matchedDefaultListItem = this.optionsArray.find(
      ({ dataset }) => dataset.value === value,
    );

    if (matchedDefaultListItem && matchedDefaultListItem.dataset.value) {
      this.selectedItem = {
        text: matchedDefaultListItem.innerText,
        value: matchedDefaultListItem.dataset.value,
      };
      this.updateButtonText();
    }
  }

  private readonly openDropdownOptions = () => {
    this.dropdownElement.classList.add('dropdown--open');
    this.toggleButton.setAttribute('aria-expanded', 'true');
    window.addEventListener('click', this.handleClickOutside);
  };

  private readonly closeDropdownOptions = () => {
    this.dropdownElement.classList.remove('dropdown--open');
    this.toggleButton.removeAttribute('aria-expanded');
    this.clearDropdownState();
    this.onBlur && this.onBlur();
  };

  private readonly focusOnItem = (
    index: 'first' | 'last' | 'next' | 'prev',
  ) => {
    const lastItemIndex = this.optionsArray.length - 1;
    const isFocusedOnFirstItem = this.focusedItemIndex === 0;
    const isFocusedOnLastItem =
      this.focusedItemIndex === this.optionsArray.length - 1;

    switch (index) {
      case 'next':
        this.focusedItemIndex = isFocusedOnLastItem
          ? 0
          : this.focusedItemIndex + 1;
        break;
      case 'prev':
        this.focusedItemIndex = isFocusedOnFirstItem
          ? lastItemIndex
          : this.focusedItemIndex - 1;
        break;
      case 'first':
        this.focusedItemIndex = 0;
        break;
      case 'last':
        this.focusedItemIndex = lastItemIndex;
        break;
      default:
        break;
    }

    const focusedOption = this.optionsArray[this.focusedItemIndex];
    focusedOption.focus();
    focusedOption.setAttribute('aria-selected', 'true');
  };

  private readonly clearDropdownState = () => {
    this.optionsArray.forEach((option) => {
      option.removeAttribute('aria-selected');
    });
    this.focusedItemIndex = 0;
  };

  private readonly selectItem = () => {
    const optionItem = this.optionsArray[this.focusedItemIndex];
    this.selectedItem = {
      value: optionItem.dataset.value || null,
      text: optionItem.innerText,
    };
    this.updateButtonText();
    this.onSelect();
    this.closeDropdownOptions();
  };

  private readonly handleClickOutside = (event: MouseEvent) => {
    if (!event.target) {
      return;
    }

    const target = event.target as Node;

    const hasClickedOnButton =
      target === this.toggleButton || this.toggleButton.contains(target);
    if (!hasClickedOnButton) {
      this.closeDropdownOptions();
      window.removeEventListener('click', this.handleClickOutside);
    }
  };

  private readonly updateButtonText = () => {
    if (this.toggleButton) {
      this.toggleButton.innerText =
        (this.selectedItem && this.selectedItem.text) || '';
    }
  };

  private readonly handleItemClick = (event: MouseEvent) => {
    const item = event.target as HTMLElement;
    this.selectedItem = {
      value: item.dataset.value || null,
      text: item.innerText,
    };
    this.updateButtonText();
    this.onSelect();
    this.closeDropdownOptions();
  };

  private readonly handleToggleButtonClick = () => {
    const isDropdownOpen =
      this.dropdownElement.classList.contains('dropdown--open');

    if (isDropdownOpen) {
      this.closeDropdownOptions();
    } else {
      this.openDropdownOptions();
    }
  };

  private readonly handleToggleButtonKeyPress = (event: KeyboardEvent) => {
    if (event.key === 'ArrowDown' || event.key === 'Enter') {
      event.preventDefault();
      this.openDropdownOptions();
      this.focusOnItem('first');
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      this.openDropdownOptions();
      this.focusOnItem('last');
    }
  };

  private readonly handleOptionsListKeyPress = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        this.focusOnItem('next');
        break;
      case 'ArrowUp':
        event.preventDefault();
        this.focusOnItem('prev');
        break;
      case 'Home':
        event.preventDefault();
        this.focusOnItem('first');
        break;
      case 'End':
        event.preventDefault();
        this.focusOnItem('last');
        break;
      case 'Enter':
        event.preventDefault();
        this.selectItem();
        this.toggleButton.focus();
        break;
      case 'Escape':
        this.closeDropdownOptions();
        this.toggleButton.focus();
        break;
      case 'Tab':
        this.closeDropdownOptions();
        break;
      default:
        break;
    }
  };

  private readonly addEventListeners = () => {
    this.toggleButton.addEventListener('click', this.handleToggleButtonClick);
    this.toggleButton.addEventListener(
      'keydown',
      this.handleToggleButtonKeyPress,
    );

    this.optionsList.addEventListener(
      'keydown',
      this.handleOptionsListKeyPress,
    );

    this.optionsArray.forEach((element) => {
      element.addEventListener('click', this.handleItemClick);
    });
  };
}

export function initDropdown(options: DropdownOptions) {
  return new Dropdown(options);
}
