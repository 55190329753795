import { initializeValidation } from './validation-form';
import FlashMessage from './flash-message';

export const initSimpleForm = (
  formElement: HTMLFormElement | Document = document,
) => {
  const flashMessage = new FlashMessage();
  flashMessage.display();
  initializeValidation(formElement);
};
