import {
  initContactFormWithLocationSelect,
  ContactFormWithLocationSelect,
} from '../../../components/forms/contact-form-with-location-select';
import { getTaggedElements } from '../../../utils/get-tagged-element';
import { initFaq } from '../../../components/faq-section/init-faq';
import { initLocationHeaderDropdown } from '../../../components/location-header-dropdown/location-header-dropdown';

export function initContactGlobalPage() {
  initFaq();
  initLocationHeaderDropdown();

  const $contactForm = document.getElementById(
    'contact-form',
  ) as HTMLFormElement;

  const contactForm = initContactFormWithLocationSelect($contactForm);
  initContactMaps(contactForm);
}

function initContactMaps(form?: ContactFormWithLocationSelect) {
  const $contactButtons =
    getTaggedElements<HTMLButtonElement>('contact-location');

  $contactButtons.forEach(($btn) =>
    $btn.addEventListener('click', scrollToContactForm),
  );

  if (form) {
    $contactButtons.forEach((btn) =>
      btn.addEventListener('click', setLocation),
    );
  }

  function setLocation(ev: Event) {
    const $button = ev.target as HTMLButtonElement;
    if (form) {
      form.setLocationSelectValue($button.dataset.jsContactLocation);
    }
  }

  function scrollToContactForm() {
    const $contactsSection = document.querySelector(
      '.contact-section',
    ) as HTMLElement;

    $contactsSection.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }
}
