import './topbar.scss';

import { initDropdown } from '../dropdown/dropdown';
import { getTaggedElement } from '../../utils/get-tagged-element';
import { getCurrentLocale, changeLocale } from '../../utils/locales';

export default function initTopbarScripts() {
  const localeDropdownElement =
    getTaggedElement<HTMLDivElement>('locale-dropdown');

  const localeDropdown = initDropdown({
    dropdownElement: localeDropdownElement,
    onSelect: (selectedLanguage) => {
      if (selectedLanguage && selectedLanguage.value) {
        changeLocale(selectedLanguage.value);
      }
    },
  });

  localeDropdown.updateSelectedOptionByValue(getCurrentLocale() || 'en');
}
