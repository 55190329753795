import { initSlider } from '../../../components/slider/slider';
import { getTaggedElement } from '../../../utils/get-tagged-element';
import { initContactFormWithLocationSelect } from '../../../components/forms/contact-form-with-location-select';
import { initLocationHeaderDropdown } from '../../../components/location-header-dropdown/location-header-dropdown';

export function initFranchiseGlobalPage() {
  initSlider(getTaggedElement('slider-section'));
  initContactFormWithLocationSelect(
    document.getElementById('contact-form') as HTMLFormElement,
  );
  initLocationHeaderDropdown();
}
