import { initDropdown, SelectedItem } from '../dropdown/dropdown';
import { getTaggedElement } from '../../utils/get-tagged-element';

export function initLocationHeaderDropdown() {
  const goToLocationButton = getTaggedElement<HTMLAnchorElement>(
    'go-to-location-button',
  );
  const locationDropdown =
    getTaggedElement<HTMLDivElement>('location-dropdown');

  initDropdown({
    dropdownElement: locationDropdown,
    onSelect: updateGoToLocationLink,
  });

  function updateGoToLocationLink(item?: SelectedItem) {
    if (item) {
      goToLocationButton.removeAttribute('disabled');
      goToLocationButton.href = localizeUrl(
        window.location.pathname,
        item.value || '',
      );
    }
  }

  function localizeUrl(url: string, location: string) {
    const oldUrl = url.split('/');
    const newUrl = [
      ...oldUrl.slice(0, 2),
      `locations/${location}`,
      ...oldUrl.slice(2),
    ];
    return newUrl.join('/');
  }
}
