import { getTaggedElement } from '../../utils/get-tagged-element';
import './navbar.scss';

export default function initMobileMenuToggle() {
  const toggleButton =
    getTaggedElement<HTMLButtonElement>('mobile-menu-toggle');
  const mobileMenu = getTaggedElement<HTMLDivElement>('mobile-menu');

  toggleButton.addEventListener('click', toggleMobileMenu);

  function toggleMobileMenu() {
    mobileMenu.classList.toggle('menu--open');
    toggleButton.classList.toggle('mobile-menu-toggle--open');
  }
}
