export function queryTaggedElement<T extends Element = Element>(
  dataJsAttributeName: string,
  parentElement: HTMLElement | DocumentFragment | Document = document,
) {
  const { elements } = findElements<T>(dataJsAttributeName, parentElement);
  return elements.length ? elements[0] : null;
}

export function getTaggedElement<T extends HTMLElement = HTMLElement>(
  dataJsAttributeName: string,
  parentElement: HTMLElement | DocumentFragment | Document = document,
) {
  const elements = getTaggedElements<T>(dataJsAttributeName, parentElement);
  return elements[0];
}

export function getTaggedElements<T extends Element = Element>(
  dataJsAttributeName: string,
  parentElement: HTMLElement | DocumentFragment | Document = document,
) {
  const { elements, query } = findElements<T>(
    dataJsAttributeName,
    parentElement,
  );
  if (elements.length === 0) {
    throw new Error(`Expect "${query}" element to exists in DOM tree`);
  }
  return elements;
}

export function findElements<T extends Element = Element>(
  dataJsAttributeName: string,
  parentElement: HTMLElement | DocumentFragment | Document = document,
) {
  const query = `[data-js-${dataJsAttributeName}]`;
  return { elements: parentElement.querySelectorAll<T>(query), query };
}
