import { initSimpleForm } from '../../../components/forms/simple-form';
import { initFileField } from '../../../components/file-upload/init-file-field';
import { initFaq } from '../../../components/faq-section/init-faq';
import { getTaggedElement } from '../../../utils/get-tagged-element';

export function initContactLocationPage() {
  initFaq();

  const $contactForm = document.getElementById(
    'contact-form',
  ) as HTMLFormElement;

  const $contactButton = getTaggedElement('contact-location');

  if ($contactButton) {
    $contactButton.addEventListener('click', scrollToContactFormOnClick);
  }

  initSimpleForm($contactForm);

  const $jobApplicationForm = document.getElementById(
    'job-application-form',
  ) as HTMLFormElement;

  if ($jobApplicationForm) {
    initSimpleForm($jobApplicationForm);
    initFileField($jobApplicationForm);
  }

  function scrollToContactFormOnClick() {
    $contactForm.scrollIntoView({
      block: 'center',
      behavior: 'smooth',
    });
  }
}
