import 'tiny-slider/src/tiny-slider.scss';
import { tns, TinySliderInstance } from 'tiny-slider/src/tiny-slider';

import './games-carousel.scss';
import { getTaggedElement } from '../../utils/get-tagged-element';
import { ContestantType } from '../../utils/data-structures';
import { mapUrlBySize, isMobile } from '../../utils/responsive';

import { GamePhoto } from '../../utils/data-structures';

interface Filter {
  contestantType: ContestantType;
}

let gamesCarousel: GamesCarousel | null = null;

class GamesCarousel {
  private lastActiveCardElements: Element[] = [];
  private slider?: TinySliderInstance;
  private sliderContainer: HTMLElement;
  private allGamesContainer: HTMLDivElement;

  constructor(filter: Filter) {
    this.sliderContainer = getTaggedElement<HTMLElement>(
      'game-carousel-container',
    );
    this.allGamesContainer = getTaggedElement<HTMLDivElement>(
      'all-cards-container',
    );

    this.sliderContainer.innerHTML = '';
    const numberOfCards = this.addCardsToCarousel(filter);

    this.slider = tns({
      container: '.my-slider',
      mouseDrag: true,
      loop: true,
      autoplay: false,
      center: true,
      items: 5,
      fixedWidth: 260,
      controls: false,
      touch: true,
      freezable: true,
      responsive: {
        '400': {
          items: 5,
          fixedWidth: 300,
        },
        '514': {
          items: 5,
          fixedWidth: 430,
        },
        '1292': {
          items: 5,
          fixedWidth: 430,
          mouseDrag: false,
        },
      },
    });

    if (numberOfCards) {
      this.updateStyles();
      this.sliderContainer.addEventListener(
        'click',
        this.handleSliderClick,
        false,
      );
      this.slider.events.on('indexChanged', this.updateStyles);
      this.slider.events.on('newBreakpointStart', this.updateStyles);
      this.slider.events.on('newBreakpointEnd', this.updateStyles);
    }
  }

  public readonly destroySlider = () => {
    if (this.slider) {
      this.slider.destroy();
    }
  };

  private readonly shiftSlider = (distance: number) => {
    const direction = distance > 0 ? 'next' : 'prev';
    if (distance && this.slider) {
      for (let i = 0; i < Math.abs(distance); i++) {
        this.slider.goTo(direction);
      }
    }
  };

  private readonly handleSliderClick = (event: MouseEvent) => {
    const clickedOverlay = event.target as HTMLElement;
    const clickedCard = clickedOverlay.parentElement;

    if (!clickedCard) {
      return;
    }

    const hasClickedOnSliderCard = clickedCard.classList.contains(
      'games-carousel__card',
    );

    if (hasClickedOnSliderCard && this.slider) {
      const sliderInfo = this.slider.getInfo();
      const centerCard = sliderInfo.slideItems[sliderInfo.index];
      const distanceBetweenCenterAndClickedCard =
        this.getCardIndex(clickedCard) - this.getCardIndex(centerCard);

      this.shiftSlider(distanceBetweenCenterAndClickedCard);
    }
  };

  private readonly getCardIndex = (cardElement: Element) => {
    const containerElement = cardElement.parentNode;

    if (!containerElement) {
      return 0;
    }

    return Array.from(containerElement.children).indexOf(cardElement);
  };

  private readonly updateStyles = () => {
    if (this.slider) {
      this.clearOldStyles();
      this.addNewStyles(this.slider.getInfo().slideItems);
    }
  };

  private readonly clearOldStyles = () => {
    const dedicatedClasses = [
      'card--left-1',
      'card--left-2',
      'card--right-1',
      'card--right-2',
      'card--center',
    ];
    this.lastActiveCardElements &&
      this.lastActiveCardElements.forEach((cardElement) => {
        dedicatedClasses.forEach((className) =>
          cardElement.classList.remove(className),
        );
      });
  };

  private readonly addNewStyles = (cardElements: HTMLCollection) => {
    if (!this.slider) {
      return;
    }

    const sliderInfo = this.slider.getInfo();
    this.lastActiveCardElements = Array.from(cardElements).filter((card) =>
      card.classList.contains('tns-slide-active'),
    );

    const selectedCard = sliderInfo.slideItems[sliderInfo.index];
    const selectedIndex = this.lastActiveCardElements.indexOf(selectedCard);

    selectedCard.classList.add('card--center');
    applyClass(selectedIndex, -1, `card--left`, this.lastActiveCardElements);
    applyClass(selectedIndex, 1, `card--right`, this.lastActiveCardElements);

    function applyClass(
      selectedIndex: number,
      dir: -1 | 1,
      classPrefix: string,
      lastActiveCardElements: Element[],
    ) {
      let currIndex = selectedIndex;
      let currCard: Element | undefined;

      selectedCard.classList.add('card--center');
      do {
        currIndex = currIndex + dir;
        currCard = lastActiveCardElements[currIndex];
        if (currCard) {
          currCard.classList.add(
            `${classPrefix}-${Math.abs(selectedIndex - currIndex)}`,
          );
        }
      } while (!!currCard);
    }
  };

  private readonly setCardImage = (card: HTMLElement) => {
    const imageWrapper = card.querySelector(
      '.game-card__image-wrapper',
    ) as HTMLElement;

    const images = imageWrapper.getAttribute('data-js-images');

    if (images) {
      const parsedImages: GamePhoto[] = JSON.parse(images);
      const mappedUrls = mapUrlBySize(parsedImages);

      if (isMobile() && mappedUrls.mobile) {
        imageWrapper.style.backgroundImage = `url(${mappedUrls.mobile})`;
      } else if (mappedUrls.desktop) {
        imageWrapper.style.backgroundImage = `url(${mappedUrls.desktop})`;
      }
    }
  };

  private readonly addCardsToCarousel = (filter: Filter) => {
    const allCardsArray = Array.from(
      this.allGamesContainer.children,
    ) as HTMLElement[];

    const filteredCardsArray = this.filterCardsByTag(allCardsArray, filter);
    const preparedCards = this.assertMinimumAmountOfCards(filteredCardsArray);

    preparedCards.forEach((card) => {
      this.setCardImage(card);
      const newCard = card.cloneNode(true);
      this.sliderContainer.appendChild(newCard);
    });

    return preparedCards.length;
  };

  private readonly assertMinimumAmountOfCards = (cardsArray: HTMLElement[]) => {
    let newCardsArray: HTMLElement[] = cardsArray;

    if (newCardsArray.length !== 0 && newCardsArray.length < 5) {
      while (newCardsArray.length < 5) {
        newCardsArray = [...newCardsArray, ...cardsArray];
      }
    }

    return newCardsArray;
  };

  private readonly filterCardsByTag = (
    cards: HTMLElement[],
    filter: Filter,
  ) => {
    const { contestantType } = filter;

    return cards.filter(function (card) {
      return card.dataset.jsContestantType === contestantType;
    });
  };
}

export function createGamesCarousel(filter: Filter) {
  if (gamesCarousel) {
    gamesCarousel.destroySlider();
  }
  gamesCarousel = new GamesCarousel(filter);
}
