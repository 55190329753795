import qs from 'qs';

import { initSimpleForm } from '../../components/forms/simple-form';
import { createGamesCarousel } from '../../components/games-carousel/games-carousel';
import { getTaggedElement, findElements } from '../../utils/get-tagged-element';
import { updateQueryParams } from '../../utils/update-query-params';
import {
  initDropdown,
  SelectedItem,
  Dropdown,
} from '../../components/dropdown/dropdown';
import { ContestantTypeModal } from '../../components/modal/contestant-type-modal';
import { VideoPlayer } from '../../components/video-player/video-player';
import { ContestantType } from '../../utils/data-structures';
import { attachModalEvents } from '../../components/modal/modal';

interface Filters {
  contestant?: ContestantType;
}

const VALID_CONTESTANTS = new Set(Object.values(ContestantType));
const CONTESTANT_TYPE_FILTER_KEY = 'landing.location.filters.contestantType';
const OCCASION_TYPE_FILTER_KEY = 'landing.location.filters.occasionType';

class LocationLanding {
  contestantTypeDropdown: Dropdown;
  modal: ContestantTypeModal;

  constructor(private videoPlayer?: VideoPlayer) {
    this.modal = new ContestantTypeModal();

    const $contestantTypeDropdownElement = getTaggedElement<HTMLDivElement>(
      'contestant-type-filter-dropdown',
    );

    this.contestantTypeDropdown = initDropdown({
      dropdownElement: $contestantTypeDropdownElement,
      onSelect: (ev) => this.onContestantSelected(ev),
    });
  }

  public init() {
    this.initFilters();
    this.attachLandingEvents();
  }

  private onContestantSelected(selectedContestant?: SelectedItem) {
    const value = selectedContestant && selectedContestant.value;
    const contestantType: ContestantType | undefined = value as ContestantType;

    this.updateFilters({
      contestant: contestantType,
    });
  }

  private applyFilters({ contestant = ContestantType.Private }: Filters) {
    createGamesCarousel({
      contestantType: contestant,
    });

    // after cloning, we need to attach modals events again
    attachModalEvents();
    this.contestantTypeDropdown.updateSelectedOptionByValue(contestant);
    this.addEventListenersToPlayButtons();

    if (this.videoPlayer) {
      this.videoPlayer.applyFilters({
        contestantType: contestant,
      });
    }
  }

  private attachLandingEvents() {
    this.modal.onClose((contestant) => {
      this.updateFilters({ contestant });
      this.modal.hide();
    });

    const { elements: occasionButtons } =
      findElements<HTMLButtonElement>('find-games-button');

    Array.from(occasionButtons).forEach((button) =>
      button.addEventListener('click', () => this.onOccasionButtonClick()),
    );

    this.addEventListenersToPlayButtons();
  }

  private onOccasionButtonClick() {
    this.updateFilters({ contestant: ContestantType.Private });
    this.contestantTypeDropdown.dropdownElement.scrollIntoView({
      behavior: 'smooth',
    });
    this.modal.hide();
  }

  private getFiltersValueFromURL(): Partial<Filters> {
    const { contestant, occasion } = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    return this.validateFilters({
      contestant,
      occasion,
    });
  }

  private getFiltersFromLocalStorage(): Partial<Filters> {
    return this.validateFilters({
      contestant: localStorage.getItem(CONTESTANT_TYPE_FILTER_KEY),
      occasion: localStorage.getItem(OCCASION_TYPE_FILTER_KEY),
    });
  }

  private initFilters() {
    const filters: Filters = {
      ...this.getFiltersFromLocalStorage(),
      ...this.getFiltersValueFromURL(),
    };

    this.updateFilters(filters);

    if (filters.contestant) {
      this.modal.hide();
    }
  }

  private updateFilters(filters: Partial<Filters> = {}) {
    const newFilters = {
      ...this.getFiltersValueFromURL(),
      ...filters,
    };

    updateQueryParams({
      contestant: newFilters.contestant || null,
    });
    this.updateFiltersInLocalStorage(newFilters);
    this.applyFilters(newFilters);
  }

  private updateFiltersInLocalStorage({ contestant }: Partial<Filters>) {
    localStorage.setItem(CONTESTANT_TYPE_FILTER_KEY, contestant || '');
  }

  private handlePlayGameVideoButtonClick(buttonElement: HTMLButtonElement) {
    const { jsPlayButtonGameId: gameId } = buttonElement.dataset;

    if (gameId && this.videoPlayer) {
      this.videoPlayer.playGameVideoById(gameId);
    }
  }

  private addEventListenersToPlayButtons() {
    const { elements: videoPlayButtons } = findElements<HTMLButtonElement>(
      'play-button-game-id',
    );

    Array.from(videoPlayButtons).forEach((button) => {
      button.addEventListener('click', () =>
        this.handlePlayGameVideoButtonClick(button),
      );
    });
  }

  private validateFilters({
    contestant,
  }: {
    contestant: string | null;
    occasion: string | null;
  }): Partial<Filters> {
    const filters: Partial<Filters> = {};
    if (isContestantType(contestant)) {
      filters.contestant = contestant;
    }
    return filters;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function isContestantType(value: any): value is ContestantType {
      return Boolean(value && VALID_CONTESTANTS.has(value));
    }
  }
}

export function initLocationLanding(videoPlayer?: VideoPlayer) {
  const landing = new LocationLanding(videoPlayer);
  landing.init();
  initSimpleForm();
}
