import './spinner.scss';

export class Spinner {
  constructor(private $spinner: Element) {}

  show() {
    this.$spinner.classList.add('spinner--visible');
  }

  hide() {
    this.$spinner.classList.remove('spinner--visible');
  }
}
