import { getTaggedElement } from '../../../../utils/get-tagged-element';
import { initVideoPlayer } from '../../../../components/modal/modal';
import { loadPredefinedPhotos } from '../init-game-location-page';

function slideToBookingForm() {
  const $bookingFormContainer = getTaggedElement('booking-form-container');

  $bookingFormContainer.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
  });
}

export function initGameBookingPage() {
  slideToBookingForm();
  loadPredefinedPhotos();
  initVideoPlayer();
}
