import './modal.scss';

import MicroModal from 'micromodal';

import { Modal } from './base-modal';
import { queryTaggedElement } from '../../utils/get-tagged-element';
import { VideoPlayer } from '../video-player/video-player';

type Listeners = Record<string, () => void>;
const onShowListeners: Listeners = {};
const onHideListeners: Listeners = {};

interface ModalClass<T extends Modal> {
  new ($container: Element): T;
}

export function attachModalEvents() {
  MicroModal.init({
    onShow: handleModalShow,
    onClose: handleModalClose,
    disableFocus: true,
  });
}

export function initVideoPlayer() {
  return registerModal('video-player-modal', VideoPlayer);
}

function registerModal<T extends Modal>(id: string, modalClass: ModalClass<T>) {
  const $modalClass = queryTaggedElement(id);
  if (!$modalClass) {
    return;
  }

  if ($modalClass) {
    const instance = new modalClass($modalClass);
    onShowListeners[id] = () => instance.onShow();
    onHideListeners[id] = () => instance.onHide();

    return instance;
  }
}

function handleModalShow(modal?: HTMLElement) {
  if (modal && modal.id && onShowListeners[modal.id]) {
    onShowListeners[modal.id]();
  }
}

function handleModalClose(modal?: HTMLElement) {
  if (modal && modal.id && onHideListeners[modal.id]) {
    onHideListeners[modal.id]();
  }
}
