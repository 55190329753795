import { getTaggedElement } from '../../utils/get-tagged-element';

type FileEventTarget = EventTarget & { files: FileList };

const MAX_FILE_SIZE_BYTES = 1000 * 1000 * 10; // 10 MB

export function initFileField($form: HTMLFormElement) {
  const $fileList = getTaggedElement<HTMLUListElement>('file-list');
  const $uploadFilesButton = getTaggedElement<HTMLButtonElement>(
    'upload-files-button',
    $form,
  );
  const $fileItemTemplate = getTaggedElement<HTMLTemplateElement>(
    'file-item-template',
    $form,
  );
  const $fileTooBigErrorMessage = getTaggedElement<HTMLSpanElement>(
    'file-too-big-error',
    $form,
  );

  const $requiredError = getTaggedElement<HTMLSpanElement>(
    'file-input-required-error',
    $form,
  );

  initFileField();

  function initFileField() {
    $uploadFilesButton.addEventListener('click', handleAddFileClick);
    initRequiredError();
  }

  function handleAddFileClick() {
    showError($fileTooBigErrorMessage, false);
    showError($requiredError, false);

    const $fileInputListItem = $fileItemTemplate.content.firstElementChild;

    if (!$fileInputListItem) {
      return;
    }

    const newFileItem = $fileInputListItem.cloneNode(true) as HTMLLIElement;

    removeEmptyFileElements();
    const preparedElement = prepareFileElement(newFileItem);
    addFileElementToList(preparedElement);
    openFileElementInputWindow(preparedElement);
  }

  function prepareFileElement(element: HTMLLIElement) {
    const deleteButton = getTaggedElement<HTMLButtonElement>(
      'delete-file-button',
      element,
    );

    deleteButton.addEventListener('click', () => {
      element.remove();
    });
    return element;
  }

  function addFileElementToList(element: HTMLLIElement) {
    $fileList.appendChild(element);
  }

  function openFileElementInputWindow(element: HTMLLIElement) {
    const input = getTaggedElement<HTMLInputElement>(
      'file-upload-hidden-input',
      element,
    );

    input.addEventListener('change', (event: Event) =>
      handleInputChange(event, element),
    );

    input.click();
  }

  function showFileElement(element: HTMLLIElement, fileName: string) {
    element.classList.remove('file-upload-field__file-item--hidden');
    const fileNameContainer = getTaggedElement<HTMLSpanElement>(
      'file-name-container',
      element,
    );
    fileNameContainer.textContent = fileName;
  }

  function handleInputChange(event: Event, element: HTMLLIElement) {
    const { files } = event.target as FileEventTarget;
    const { size, name } = files[0];

    if (size < MAX_FILE_SIZE_BYTES) {
      showFileElement(element, name);
    } else {
      element.remove();
      showError($fileTooBigErrorMessage, true);
    }
  }

  function removeEmptyFileElements() {
    const allFileElements = Array.from($fileList.children) as HTMLLIElement[];

    allFileElements.forEach((element) => {
      const isEmpty = element.classList.contains(
        'file-upload-field__file-item--hidden',
      );

      if (isEmpty) {
        element.remove();
      }
    });
  }

  function initRequiredError() {
    $form.addEventListener('submit', (event) => {
      const $fileInputs = Array.from(
        $fileList.querySelectorAll('input[type=file]'),
      ) as Array<HTMLInputElement>;

      const isValid = $fileInputs.some(
        ($fileInput) => $fileInput.files && $fileInput.files.length,
      );

      showError($requiredError, !isValid);

      if (!isValid) {
        event.preventDefault();
      }
    });
  }

  function showError($element: HTMLElement, showMessage: boolean) {
    $element.classList.toggle(
      'file-upload-field__file-error--visible',
      showMessage,
    );
  }
}
