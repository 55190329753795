import { getTaggedElements } from '../../utils/get-tagged-element';

export function initScrollOnReadmeButtons() {
  const $readMeButtons =
    getTaggedElements<HTMLButtonElement>('package-section');

  $readMeButtons.forEach(($btn) =>
    $btn.addEventListener('click', scrollToPackageSection),
  );
}

export function initScrollOnInquireButtons() {
  const $inquireButtons =
    getTaggedElements<HTMLButtonElement>('inquire-button');

  $inquireButtons.forEach(($btn) =>
    $btn.addEventListener('click', scrollToContactForm),
  );
}

function scrollToPackageSection(event: Event) {
  const $button = event.currentTarget as HTMLButtonElement;
  const searchedSectionId = $button.getAttribute('data-js-package-section');
  const $packageSection = document.querySelector(
    `#${searchedSectionId}`,
  ) as HTMLElement;

  $packageSection.scrollIntoView({
    block: 'center',
    behavior: 'smooth',
  });
}

function scrollToContactForm() {
  const $contactForm = document.querySelector('#contact-form') as HTMLElement;

  $contactForm.scrollIntoView({
    block: 'center',
    behavior: 'smooth',
  });
}
