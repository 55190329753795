import * as qs from 'qs';
import pickBy from 'lodash/pickBy';

export function updateQueryParams(queryObject: object) {
  const argumentIsObject =
    Object.prototype.toString.call(queryObject) === '[object Object]';
  if (!argumentIsObject) {
    return;
  }

  const currentUrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });

  const newUrlParams = pickBy<string | null | undefined>(
    { ...currentUrlParams, ...queryObject },
    (value) => !!value,
  );

  const stringifiedNewUrlParams = qs.stringify(newUrlParams, {
    addQueryPrefix: true,
  });

  const { pathname } = window.location;
  history.pushState(null, '', `${pathname}${stringifiedNewUrlParams}`);
}
