import './faq-section.scss';

export function initFaq() {
  const allQuestions = document.querySelectorAll('[data-faq-question]');
  const allQuestionsArray = Array.from(allQuestions) as HTMLLIElement[];

  allQuestionsArray.forEach(function (question) {
    question.addEventListener('click', function () {
      question.classList.toggle('faq-section__single-faq--collapsed');
    });
  });
}
