import { getTaggedElement } from '../../utils/get-tagged-element';
import { ContestantType } from '../../utils/data-structures';

type OnCloseCallback = (contestantType: ContestantType) => void;

export class ContestantTypeModal {
  $blurredOverlay: HTMLDivElement;
  $blurredOverlayWrapper: HTMLDivElement;
  $contestantTypeModal: HTMLDivElement;
  onCloseCallback?: OnCloseCallback;

  constructor() {
    this.$blurredOverlay = getTaggedElement<HTMLDivElement>('blurred-overlay');
    this.$blurredOverlayWrapper = getTaggedElement<HTMLDivElement>(
      'blurred-overlay-wrapper',
    );
    this.$contestantTypeModal = getTaggedElement<HTMLDivElement>(
      'contestant-type-modal',
    );

    const modalForCorporateButton = getTaggedElement<HTMLButtonElement>(
      'modal-for-corporate-button',
    );
    const modalForPrivateButton = getTaggedElement<HTMLButtonElement>(
      'modal-for-private-button',
    );
    modalForCorporateButton.addEventListener('click', () =>
      this.triggerOnCloseCallback(ContestantType.Corporate),
    );
    modalForPrivateButton.addEventListener('click', () =>
      this.triggerOnCloseCallback(ContestantType.Private),
    );
  }

  public onClose(callback: OnCloseCallback) {
    this.onCloseCallback = callback;
  }

  public hide() {
    this.$blurredOverlay.classList.add('header__modal-blur-overlay--hidden');
    this.$contestantTypeModal.classList.add('contestant-type-modal--hidden');
    this.$blurredOverlayWrapper.classList.add(
      'header__modal-blur-overlay-wrapper--hidden',
    );
  }

  private triggerOnCloseCallback(contestant: ContestantType) {
    this.onCloseCallback && this.onCloseCallback(contestant);
  }

  private updateContestantTypeUrlParam(contestant: ContestantType) {
    this.onCloseCallback && this.onCloseCallback(contestant);
  }
}
