import * as minimatch from 'minimatch';

type Pattern = string | RegExp;

export function runOnRoutes(
  patterns: Pattern | Pattern[],
  callback: () => void,
) {
  if (!Array.isArray(patterns)) {
    patterns = [patterns];
  }
  const { pathname } = window.location;
  const isRouteMatch = patterns.some((pattern) =>
    typeof pattern === 'string'
      ? minimatch(pathname, pattern)
      : pattern.test(pathname),
  );

  if (isRouteMatch) {
    callback();
  }
}
