import { GamePhoto } from '../utils/data-structures';

const BreakpointPx = 850;

export function isMobile() {
  return getViewportSize().vw < BreakpointPx;
}

export function isDesktop() {
  return getViewportSize().vw >= BreakpointPx;
}

export function getViewportSize() {
  const vw = Math.max(
    document.documentElement.clientWidth,
    window.innerWidth || 0,
  );
  const vh = Math.max(
    document.documentElement.clientHeight,
    window.innerHeight || 0,
  );
  return { vw, vh };
}

interface SizeUrlMapping {
  mobile?: string;
  desktop?: string;
}

export function mapUrlBySize(photos: GamePhoto[]) {
  return photos.reduce<SizeUrlMapping>(
    (prev: SizeUrlMapping, curr: GamePhoto) => {
      return { ...prev, [curr.size]: curr.url };
    },
    {},
  );
}
