import { initContactFormWithLocationSelect } from '../../components/forms/contact-form-with-location-select';
import { initLocationHeaderDropdown } from '../../components/location-header-dropdown/location-header-dropdown';
import { initSlider } from '../../components/slider/slider';
import { getTaggedElement } from '../../utils/get-tagged-element';

export function initHomePage() {
  initContactFormWithLocationSelect(
    document.getElementById('contact-form') as HTMLFormElement,
  );
  initSlider(getTaggedElement('testimonials-section'));

  initLocationHeaderDropdown();
}
