import * as qs from 'qs';
import { getTaggedElement } from '../../utils/get-tagged-element';

type MessageType = 'success' | 'failure';

interface QueryURL {
  message?: string;
}

export default class FlashMessage {
  messageType?: MessageType;
  containerElement?: HTMLElement;

  constructor() {
    this.initFlashMessage();
  }

  initFlashMessage() {
    this.messageType = this.getMessageFromUrl();

    if (this.messageType) {
      this.containerElement = getTaggedElement<HTMLDivElement>(
        'flash-message-container',
      );
      this.handleOnClose();
    }
  }

  display() {
    if (this.messageType) {
      this.chooseMessage(this.messageType);
      this.showMessage();
    }
  }

  private getMessageFromUrl() {
    const { message }: QueryURL = qs.parse(location.search, {
      ignoreQueryPrefix: true,
    });

    if (message && ['success', 'failure'].includes(message)) {
      return message as MessageType;
    }
  }

  private showMessage() {
    if (this.containerElement) {
      this.containerElement.classList.add('flash-message__container--show');
    }
  }

  private chooseMessage(messageType: MessageType) {
    const textElement = getTaggedElement<HTMLParagraphElement>(
      `flash-message-text-${messageType}`,
    );

    if (textElement) {
      textElement.classList.add('flash-message__text--show');
    }
  }

  private handleOnClose() {
    const closeButtonElement = getTaggedElement<HTMLParagraphElement>(
      'flash-message-close-button',
    );

    closeButtonElement &&
      closeButtonElement.addEventListener('click', () => {
        this.containerElement?.remove();
      });
  }
}
