export interface Language {
  code: string;
  name: string;
}

export enum ContestantType {
  Private = 'private',
  Corporate = 'corporate',
}

export interface GameInfo {
  id: string;
  name: string;
  gameUrl: string;
  desktopVideoUrl: string;
  mobileVideoUrl: string;
  contestantType: ContestantType;
}

export interface GamePhoto {
  id: string;
  size: 'desktop' | 'mobile';
  url: string;
  alt: string;
}

export interface ArticleOcassionTag {
  tag: string;
  id: string;
}

export interface Article {
  id: string;
  title: string;
  photoCover: {
    url: string | null;
    alt: string | null;
  };
  description: string;
  suitableOccasions: ArticleOcassionTag[];
  link_to_article_details: string;
}
