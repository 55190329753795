import { initSlider } from '../../../components/slider/slider';
import { getTaggedElement } from '../../../utils/get-tagged-element';
import { initSimpleForm } from '../../../components/forms/simple-form';
import {
  initScrollOnReadmeButtons,
  initScrollOnInquireButtons,
} from '../init-on-scroll-buttons';

export function initPortableActivitiesLocationPage() {
  initSlider(getTaggedElement('package-slider'));
  initSlider(getTaggedElement('shows-slider'));

  initSimpleForm();

  initScrollOnReadmeButtons();
  initScrollOnInquireButtons();
}
