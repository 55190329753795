import { initContactFormWithLocationSelect } from '../../../components/forms/contact-form-with-location-select';
import { initOccasionButtons } from '../../../components/occation-buttons/init-occation-buttons';
import { initLocationHeaderDropdown } from '../../../components/location-header-dropdown/location-header-dropdown';

export function initCompaniesGlobalPage() {
  const $form = document.getElementById('contact-form') as HTMLFormElement;

  initContactFormWithLocationSelect($form);
  initOccasionButtons();
  initLocationHeaderDropdown();
}
