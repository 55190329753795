import { initVideoPlayer } from '../../../components/modal/modal';
import { initSlider } from '../../../components/slider/slider';
import { initLocationLanding } from '../location';
import { getTaggedElement } from '../../../utils/get-tagged-element';

export function initLocationsPage() {
  initSlider(getTaggedElement('testimonials-section'));

  const videoPlayer = initVideoPlayer();
  initLocationLanding(videoPlayer);
}
