import './blog-article.scss';

import { Article } from '../../utils/data-structures';

export function createBlogArticleElement(
  $template: HTMLTemplateElement,
  article: Article,
) {
  if (!$template || !$template.content.firstElementChild) {
    throw new Error('Can not find article template in html');
  }

  const $article = $template.content.firstElementChild.cloneNode(
    true,
  ) as HTMLElement;

  const $readMoreLink = $article.querySelector<HTMLAnchorElement>(
    '.blog-article__read-more',
  );
  $readMoreLink?.setAttribute('href', article.link_to_article_details);

  const $img = $article.querySelector<HTMLImageElement>('.blog-article__image');
  if ($img && article.photoCover) {
    $img.setAttribute('alt', article.photoCover.alt || '');
    $img.setAttribute('src', article.photoCover.url || '');
  }

  const $title = $article.querySelector<HTMLImageElement>(
    '.blog-article__description-header',
  );
  if ($title) {
    $title.textContent = article.title || 'Unknown';
  }

  const $content = $article.querySelector<HTMLImageElement>(
    '.blog-article__description-content',
  );

  if ($content) {
    $content.textContent = article.description || 'Unknown';
  }

  return $article;
}
