const supportedLocalesCodes = new Set(
  window.preloadedData.supportedLanguages.map((lang) => lang.code),
);

export function getCurrentLocale() {
  const { pathname } = window.location;
  const urlSegments = pathname.split('/');

  return urlSegments.find(function (segment) {
    return supportedLocalesCodes.has(segment);
  });
}

export function changeLocale(locale: string) {
  const { pathname, search } = window.location;
  if (locale === getCurrentLocale() || !supportedLocalesCodes.has(locale)) {
    console.log('Unssuported locale: ', locale);
    return;
  }

  window.location.href = `/${locale}/${getURLWithoutLocale(pathname)}${search}`;
}

function getURLWithoutLocale(url: string) {
  const urlSegments = url.split('/');
  const urlSegmentsWithoutLocale = urlSegments.filter(
    (segment) => !supportedLocalesCodes.has(segment) && segment !== '',
  );
  return urlSegmentsWithoutLocale.join('/');
}
