import { getTaggedElement } from '../../utils/get-tagged-element';
import { initDropdown, SelectedItem, Dropdown } from '../dropdown/dropdown';
import { checkValidation } from './validation-form';
import { initSimpleForm } from './simple-form';
import FlashMessage from './flash-message';

export class ContactFormWithLocationSelect {
  locationErrorElement: HTMLElement;
  locationSelect: HTMLDivElement;
  locationHiddenInput: HTMLInputElement | null;
  dropdown?: Dropdown;

  constructor(private formElement: HTMLFormElement) {
    this.locationErrorElement = formElement.querySelector(
      '#error-location',
    ) as HTMLElement;
    this.locationSelect = getTaggedElement<HTMLDivElement>(
      'location-form-dropdown',
    );
    this.locationHiddenInput = formElement.location;
  }

  public initForm = () => {
    initSimpleForm(this.formElement);
    this.initLocationSelect();
  };

  private onSelectLocation = (item?: SelectedItem) => {
    if (this.locationHiddenInput) {
      this.locationHiddenInput.value = (item && item.value) || '';
    }
  };

  private onBlurLocation = () => {
    if (this.locationErrorElement && this.locationHiddenInput) {
      checkValidation(
        this.locationErrorElement,
        this.locationHiddenInput,
        'valueMissing',
      );
    }
  };

  private initLocationSelect = () => {
    this.dropdown = initDropdown({
      dropdownElement: this.locationSelect,
      onSelect: this.onSelectLocation,
      onBlur: this.onBlurLocation,
    });
  };

  public setLocationSelectValue = (slug?: string) => {
    if (this.dropdown) {
      this.dropdown.updateSelectedOptionByValue(slug || '');
    }

    if (this.locationHiddenInput) {
      this.locationHiddenInput.value = slug || '';
    }
  };
}

export function initContactFormWithLocationSelect(
  formElement: HTMLFormElement,
) {
  const contactForm = new ContactFormWithLocationSelect(formElement);
  const flashMessage = new FlashMessage();

  contactForm.initForm();
  flashMessage.display();

  return contactForm;
}
